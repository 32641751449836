
.react-datepicker {
  width: auto;
  max-width: 604px;
  min-width:304px;
  font-family: 'Pretendard', sans-serif;
  display: flex;
  border: none;
  padding-top: 1px;

}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

.react-datepicker__month-container {
  width: 100%;
  border-left: 0.5px solid #f1f2f4;
  border-right: 0.5px solid #f1f2f4;
  border-bottom: 0.5px solid #f1f2f4;
  padding: 5px 10px;
}

.react-datepicker__current-month {
  font-size: 16px;
  font-weight: 600;
  color: #1e2024;
}

.react-datepicker__month {
  display: grid;
  grid-template-columns: repeat(7, 1fr); 
  justify-items: center; 
  margin:0px;

}

.react-datepicker__week {
  display: contents; 
}

.react-datepicker__navigation {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #f1f2f4;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
}

.react-datepicker__navigation-icon::before {
  border-color: #1e2024;
  border-style: solid;
  border-width: 1.5px 1.5px 0 0;
  margin : 3px 4px 4px 4px;
  display: inline-block;
  height: 6px;
  width: 6px;
  justify-items: center; 
}

.react-datepicker__navigation--previous {
  left: 15px;
}

.react-datepicker__navigation--next {
  right: 15px;
}


.react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center; 
}

.react-datepicker__day-name {
  color: #686b73;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}

.react-datepicker__day {
  color: #1e2024;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 50%;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day:hover {
  background-color: #576ee0;
  color: white;
  border-radius: 50%;
  opacity:0.5;
}

.react-datepicker__day--selected {
  position: relative;
  background-color: #576ee0;
  color: white;
  border-radius: 50%;
  font-weight: 500;
  z-index: 1;
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range {
  position: relative;
  background-color: #576ee0;
  color: #686b73;
  border-radius: 0;
  width: 100%;
  z-index: 0;
}

.react-datepicker__day--outside-month {
  color: #686b73;
}


.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range, .react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start),
.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover {
  background-color: #576ee0;
  color: #E2E2E2;
  opacity: 0.5;
  border-radius: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.react-datepicker__day--range-start:hover,
.react-datepicker__day--selecting-range-start:hover {
  background-color: #576ee0;
  color: white;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.react-datepicker__day--selecting-range-end:hover {
  background-color: #576ee0;
  color: white;
  border-radius: 0 50% 50% 0;
  position: relative;
  z-index: 2;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  background-color: #576ee0;
  color: white;
  border-radius: 50% 0 0 50%;
  position: relative;
  z-index: 2;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end {
  background-color: #576ee0;
  color: white;
  border-radius: 0 50% 50% 0;
  position: relative;
  z-index: 2;
}